<!-- eslint-disable vue/no-v-html -->
<script setup lang="ts">
import { CmsBlock, CmsSlot } from "@shopware-pwa/types";

type CmsBlockMaasJobDetail = CmsBlock & {
  slots: Array<
    CmsSlot & {
      slot: "maasJobDetail";
    }
  >;
};

const props = defineProps<{
  content: CmsBlockMaasJobDetail;
}>();

const jobData = (props.content.slots?.[0] as any).config?.job?.value ?? {};

const form = ref(null);
const showForm = ref(false);

const toggleForm = (e: Event) => {
  e.preventDefault();
  showForm.value = !showForm.value;

  if (showForm.value && form.value != null) {
    form.value.scrollIntoView({ behavior: "smooth" });
  }
};

const formattedStartDate = computed(() => {
  if (!jobData.startDate) return "";
  const date = new Date(jobData.startDate);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
});

</script>

<!-- TODO: Checks for Variable content should be added -->
<template>
  <div class="pt-12">
   <div v-if="jobData" class="s-gutter">
      <div class="c-width-narrow">
        <h1 class="text-h1 text-maas-typography-headline-primary">{{ jobData.title }}</h1>
        <ul class="text-h4 mt-4 flex gap-6 font-normal">
          <li v-if="jobData.type"><strong>Umfang:</strong> {{ jobData.type }}</li>
          <li v-if="jobData.startDate"><strong>Startdatum:</strong> {{ formattedStartDate }}</li>
          <li v-if="jobData.location.city"><strong>Standort:</strong> {{ jobData.location.city }}</li>
        </ul>
        <div class="mt-10 grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-24">
          <div>
            <div v-if="jobData.tasks">
              <h3 class="text-h3">Ihre Aufgaben</h3>
              <div class="richtext large mt-4" v-html="jobData.tasks"></div>
            </div>

            <div v-if="jobData.skills">
              <h3 class="text-h3 mt-10">Das bringen Sie mit</h3>
              <div class="richtext large mt-4" v-html="jobData.skills"></div>
            </div>
          </div>
          <div>
            <div v-if="jobData.benefits">
              <h3 class="text-h3">Das erwartet Sie bei uns</h3>
              <div class="richtext large mt-4" v-html="jobData.benefits"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-maas-background-grey-light s-gutter mt-12 py-12 xl:mt-24">
      <div class="c-width-default flex flex-col items-center justify-center gap-12 md:flex-row">
        <SharedCmsImage
          v-if="jobData.contact?.media"
          :src="jobData.contact.media.url"
          :focus-point="jobData.contact.focuspoint"
          :crop="jobData.contact.crop"
          width="250"
          height="250"
          class-img="max-w-40 rounded-full md:max-w-none"
        />
        <div class="text-center md:text-left">
          <h3 class="text-h3">{{ jobData.contact.firstname }} {{ jobData.contact.lastname }}</h3>
          <p v-if="jobData.contact.position" class="text-p">{{ jobData.contact.position }}</p>
          <p
            v-if="jobData.contact.phone || jobData.contact.email"
            class="text-p flex flex-wrap justify-center gap-2 md:justify-start"
          >
            <a
              v-if="jobData.contact.phone"
              :href="'tel:' + jobData.contact.phone"
              class="text-a inline-block"
              >{{ jobData.contact.phone }}</a
            >
            <span v-if="jobData.contact.phone && jobData.contact.email">|</span>
            <a
              v-if="jobData.contact.email"
              :href="'mailto:' + jobData.contact.email"
              class="text-a inline-block"
              >{{ jobData.contact.email }}</a
            >
          </p>
          <SharedFormButton
            type="button"
            layout="primary"
            size="big"
            class="mt-6"
            :class="{
              hidden: showForm,
            }"
            @click="(e) => toggleForm(e)"
            >Jetzt bewerben!</SharedFormButton
          >
        </div>
      </div>

      <div ref="form">
        <div
          class="border-t-1 relative mt-20 pt-10"
          :class="{
            hidden: !showForm,
          }"
        >
          <a
            href="#"
            class="bg-maas-background-grey-medium absolute right-0 top-6 flex h-8 w-8 items-center justify-center rounded-full text-sm"
            @click="(e) => toggleForm(e)"
            >✕</a
          >

          <SharedFormCompositionJob :job="jobData" class="c-width-narrow" />
        </div>
      </div>
    </div>
  </div>
</template>
